import { FC, useCallback, useContext } from 'react';
import classNames from 'classnames';
// context
import CursorContext from 'src/contexts/CursorContext';

import type { Props } from './Pagination.interface';
import * as style from './Pagination.module.scss';

const MAX_ZERO_NUMBER = 9;

const Pagination: FC<Props> = ({
  variant,
  itemsCount,
  activeItem,
  onClick,
}) => {
  // context
  const { cursor } = useContext(CursorContext);

  const handlePageMouseEnter = useCallback(() => {
    cursor?.enter();
  }, [cursor]);

  const handlePageMouseLeave = useCallback(() => {
    cursor?.leave();
  }, [cursor]);

  return (
    <div
      className={classNames(style.root, {
        [style.dots]: variant === 'dots' || variant === 'dotsWithoutNumber',
      })}
    >
      {[...Array(itemsCount)].map((_, index) => (
        <button
          key={index}
          className={classNames(style.paginationItem, {
            [style.active]: activeItem === index,
          })}
          onClick={() => onClick(index)}
          onMouseEnter={handlePageMouseEnter}
          onMouseLeave={handlePageMouseLeave}
        >
          {variant !== 'dotsWithoutNumber' && (
            <>
              {variant === 'numeric' && index < MAX_ZERO_NUMBER
                ? '0' + `${index + 1}`
                : index + 1}
            </>
          )}
        </button>
      ))}
    </div>
  );
};

export default Pagination;
